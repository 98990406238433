@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https: //fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@layer components {
  * {
    @apply box-border;
  }

  .btn {
    @apply mr-2 inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg;
  }
}

:root {
  --font-poppins: "Poppins", sans-serif;
}

body {
  font-family: var(--font-poppins);
  background-color: black;
}

@keyframes loadingEffect {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.my-recaptcha-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 0px;
}

.my-recaptcha-container div {
  transform: scale(0.85);
  -webkit-transform: scale(0.9);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.my-recaptcha-container iframe {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.spinner {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  color: #f7f7f7;
  animation: spin 1s linear infinite;
  fill: #023dd1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .custom-modal-content {
    position: fixed;
    right: 2.5rem;
    top: 11.25rem;
    left: auto;
    bottom: auto;
  }

  .slide-in-right {
    animation: slideInFromRight 1s forwards;
  }

  .slide-out-right {
    animation: slideOutToRight 1s forwards;
  }
}

.scrollbarHidden {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.scrollbarHidden::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.section-gradient {
  position: absolute;
  width: 185px;
  height: 185px;
  background: radial-gradient(
  circle, /* This makes it circular */
  rgba(69, 231, 252, 0.268) 0%, 
  rgba(69, 231, 252, 0.268) 30%, 
  rgba(140, 73, 253, 0.67) 100%
);
  filter: blur(50.8px);
  border-radius: 50%;
}

.section-gradient-right {
  right: -95px;
}
.section-gradient-left {
  left: -95px;
}
